<template>
  <div id="contactus">
    <h2>联系我们</h2>
    <div class="a_line"></div>
    <div class="company_name">江苏数字矩阵科技有限公司</div>
    <div class="place_ques">
      <div class="main_palce_ques">
        <div class="comp_place">
          <img
            class="place_ques_title"
            src="~/assets/images/aboutus/contact_us.png"
            alt="找不到资源"
          />
          <div class="comp_map">
            <div id="container" class="container"></div>
          </div>
          <div class="company_info">
            <img
              class="info_logo"
              src="~/assets/images/nav/logo.png"
              alt="找不到资源"
            />
            <div class="company_detail_info">
              <div class="comp_name">江苏数字矩阵科技有限公司</div>
              <div class="comp_add">行政运营部：南京市江宁区佛城西路88号复地朗香别墅261幢1-3F</div>
              <div class="comp_add">产品研发部：南京市建邺区江心洲江岛智立方D座1单元4F-3F</div>
              <div class="comp_email">企业邮箱：bd@jsszjz.cn</div>
              <div class="comp_call">联系电话：025-85207326</div>
            </div>
          </div>
          <div class="gofooter">见页脚</div> 
        </div>
        <div class="ques_feedback">
          <img
            class="place_ques_title"
            src="~/assets/images/aboutus/online_feedback.png"
            alt="找不到资源"
          />
          <div>
            <home-question-form></home-question-form>
          </div>
        </div>
      </div>
    </div>
    <div class="download">
      <div class="download_style">
        <div class="qrcode">
          <img src="~/assets/images/aboutus/QRcode.png" alt="找不到资源" />
        </div>
        <div class="phoneload">
          <img
            src="~/assets/images/aboutus/mc_androidDown.png"
            alt="找不到资源"
          />
          <!-- <img
            src="~/assets/images/aboutus/mc_iPhoneDown.png"
            alt="找不到资源"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeQuestionForm from "@/components/content/home/HomeQuestionForm.vue";
export default {
  name: "contactUs",
  data() {
    return {};
  },
  components: {
    HomeQuestionForm,
  },
  created() {
    window.sessionStorage.setItem("aboutus_index", 2);
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScrollx,true);
    var map = new BMapGL.Map("container"); // 创建Map实例
    map.centerAndZoom(new BMapGL.Point(118.71157303078306, 32.035076051353045), 10); // 初始化地图,设置中心点坐标和地图级别
    var marker1 = new BMapGL.Marker(new BMapGL.Point(118.71157303078306, 32.035076051353045));
    var marker2 = new BMapGL.Marker(new BMapGL.Point(118.78068380587099, 31.918639721656724));
	  // 在地图上添加点标记
    map.addOverlay(marker1);
    map.addOverlay(marker2); 
    // map.enableScrollWheelZoom(true);
  },
  methods: {
    // handleScrollx() {
    //   // console.log('滚动高度'+window.pageYOffset)
    // },
  },
};
</script>

<style lang="scss" scoped>
  // .comp_map{
  //   touch-action: pan-y;
  // }
  @media(min-width: 1201px){
    @import '@/components/content/aboutus/contactUs.scss';
  }
  @media(max-width: 1200px){
    @import '@/components/content/aboutus/contactUs_phone.scss';
  }
</style>
