<template>
	<div id="form_message">
		<form class="message_problem">
			<div class="userinput user_name"><div>姓名</div> <input type="text" v-model="name" name="Name" placeholder="请输入姓名" autocomplete="off"/></div>
			<div class="userinput user_phone"><div>手机</div> <input type="tel" v-model="phone" v-numberonly pattern="[0-9]*" name="phone" placeholder="请输入手机号码" maxlength="11"  autocomplete="off"/></div>
			<div class="userinput user_email"><div>邮箱</div> <input type="text" v-model="email" name="Email" placeholder="请输入邮箱"  autocomplete="off"/></div>
			<div class="user_leavemsg"><div class="tip_message">留言</div><textarea placeholder="请输入留言内容" v-model="content" :maxlength="textarea_count_max">
				</textarea>
				<span class="num_quantity"><span :style="textarea_count == textarea_count_max ? 'color: #ff4949;':''">{{textarea_count}}</span>/{{textarea_count_max}}</span>
			</div>
			
			<div class="submit_btn">
				<el-button plain @click="submitMsg" type="submit" class="hvr-sweep-to-right">
					提交
				</el-button>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	name:'HomeQuestionForm',
	data()	{
		return {
			name:'',					//留言__姓名
			phone:'',					//留言__电话
			email:'',					//留言__邮箱
			content:'',					//留言__内容
			textarea_count:0,			//留言__内容字数
			textarea_count_max: 150,	//留言__最大字数
			message_tip_title:'提交失败',		//提示信息标题
			message_tip_content:'还未填写任何信息哦',		//提示信息内容
			submit_state:'error',		//提交状态
			TokenCode:'',	//用户token
			UserGuid:'',	//用户guid
			flag:0,			//用户判断是否能提交1为true 0为false
			
		}
	},
	computed:{
		userinfo(){
			return this.$store.state.userinfo
		}
	},
	created() {
		if(this.userinfo != null){
			this.TokenCode = this.userinfo.TokenCode
			this.UserGuid = this.userinfo.UserGuid
		}
	},
	methods:{
		//检查手机号以及邮箱是否正确
		checkIsTrue(){
			const phone_reg = /^[1][3,4,5,7,8][0-9]{9}$/;
			const email_reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
			console.log(phone_reg.test(this.phone))
			if(phone_reg.test(this.phone) && this.phone.length === 11){
				if(email_reg.test(this.email)){
					return this.flag = 1
				}else{
					this.message_tip_content = '邮箱号不正确'
					this.tip_info()
				}
			}else{
				this.message_tip_content = '手机号不正确'
				this.tip_info()
			}
		},
		//检查是否有空值
		checkIsNull(){
			if(this.name == '' && this.phone == '' && this.email == '' && this.content == ''){
				this.message_tip_content = '还未填写任何信息哦'
				this.tip_info()
			}else{
			if(this.name == '' || this.name == undefined || this.name == null) {
				this.message_tip_content = '还未填写姓名'
				this.tip_info()
			}else{
				if(this.phone == '' || this.phone == undefined || this.phone == null){
					this.message_tip_content = '还未填写手机号'
					this.tip_info()
				}else{
					if(this.email == '' || this.email == undefined || this.email == null){
						this.message_tip_content = '还未填写邮箱'
						this.tip_info()
					}else{
						if(this.content == '' || this.content == undefined || this.content == null){
							this.message_tip_content = '还未填写内容'
							this.tip_info()
						}else{
							this.checkIsTrue()
						}
					}
				}
			}}
		},
		//未登录提示
		tipunlogin(){
			this.$confirm('未登录或登录状态失效', '提示', {
				confirmButtonText: '去登录',
				cancelButtonText: '过会再去',
				type: 'warning'
			}).then(() => {
				this.$router.push('/login')
			}).catch(() => {
				
			});
		},
		submitMsg(){
			this.checkIsNull()
			if(this.flag == 1){	
				this.$confirm('是否确认提交','提示',{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'info'
				}).then(() => {
					
					let request = {
						Version:'',
						Name:this.name,
						Phone:this.phone,
						Email:this.email,
						Message:this.content,
						ImgUrls:'',
						JZToken:this.TokenCode,
						UserGuid:this.UserGuid,
					}
					this.$axios('API/Add_FeedBack',request).then(res => {
						console.log(res)
						if( res.status == 201){
							this.tipunlogin()
						}else if(res.status == 202){
							alert('提示/说明：请刷新页面')
						}else if(res.status == 203){
							this.$message.error('操作失败');
						}else if(res.status == 200){
							this.message_tip_title = '提交成功'
							this.message_tip_content = ''
							this.submit_state = 'success'
							this.tip_info()
							this.name = '',this.phone = '',this.email = '',this.content = '',
							this.message_tip_title='提交失败',this.message_tip_content ='还未填写任何信息哦',this.submit_state = 'error'
							this.flag = 0
						}
					});
				}).catch(() => {
					this.$message.error('取消操作');
				})
			}
			
		},
		
		//check输入提示信息
		tip_info(){
			this.$notify({
				title: this.message_tip_title,
				message: this.message_tip_content,
				offset: 100,
				type: this.submit_state
			});
		},
	},
	directives:{
		numberonly:{
			bind:function(e){
				e.handler = function(){
					e.value = e.value.replace(/\D+/,'')
				}
				e.addEventListener('input',e.handler)
			},
			unbind:function(e){
				e.removeEventListener('input',e.handler)
			}
		}
	},
	watch:{
		content(){
			this.textarea_count = this.content.length
		}
	}
}
</script>

<style lang="scss" scoped>
	@media(min-width: 1201px){
		@import '@/components/content/home/HomeQuestionForm.scss';
	}
	@media(max-width: 1200px){
		@import '@/components/content/home/HomeQuestionForm_phone.scss';
	}
</style>
